.SketchfabModule {
  #api-frame {
    border: 0;
    width: 100%;
    height: 100%;
  }

  .media {
    position: relative;
    grid-column-start: 2;
    grid-column-end: span 6;
  }

  .pill-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1;
    cursor: pointer;
    display: none;

    svg {
      width: 40px;
    }

    &:hover {
      circle {
        fill: $color-black;
      }
      path {
        fill: $color-white;
      }
    }
  }

  .sketchfab-controls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 5px;
    //visibility: hidden;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    background-color: $color-white;

    .sketchfab-progress-wrap,
    .sketchfab-progress-bar {
      width: 100%;
      height: 100%;
    }

    .sketchfab-progress-wrap {
      background: $color-white;
    }

    .sketchfab-progress-bar {
      background-color: $color-black;
      width: 0;
      transition: width 1s;
    }
  }

  .media-wrapper {
    background-color: $color-image-placeholder-on-light;

    .theme-dark & {
      background-color: $color-image-placeholder-on-dark;
    }
  }

  img {
    width: 100%;
    opacity: 0;
    transition: 0.5s opacity;

    &.lazyloaded {
      opacity: 1;
    }
  }

  .grid {
    &.full {
      display: block;
      padding: 0;
      max-width: none;

      .container {
        @media only screen and (min-width: $media-breakpoint-md) {
          @include to-grid(width, 12, 3);
          @include to-grid(margin-left, 12, 1);
        }
      }

      .title-body {
        max-width: $max-page-width;
        margin: 0 auto;
        padding: 0 20px;

        @media only screen and (min-width: $media-breakpoint-md) {
          padding: 0 40px;
        }

        @media only screen and (min-width: $media-breakpoint-xl) {
          padding: 0 80px;
        }
      }
    }

    &.center {
      .media {
        grid-column-start: 4;
        grid-column-end: span 6;
      }

      .copy {
        grid-column-start: 4;
        grid-column-end: span 5;
      }
    }

    &.right {
      direction: rtl;

      & > * {
        direction: ltr;
      }
    }

    &.full {
      padding-left: 0;
      padding-right: 0;

      .media {
        grid-column-start: 1;
        grid-column-end: span 12;
        margin-bottom: 50px;
      }

      .copy {
        grid-column-start: 2;
        grid-column-end: span 5;
      }
    }

    .copy {
      grid-column-start: 9;
      grid-column-end: span 3;
      margin-top: 20px;

      @media only screen and (min-width: $media-breakpoint-md) {
        margin-top: 0;
      }
    }

    &.center .copy {
      @media only screen and (min-width: $media-breakpoint-md) {
        margin-top: 50px;
      }
    }
  }
}
