.ProofReading {
  $green: #dbffdb;
  padding: 50px;
  background-color: #fff;
  overflow: visible;
  height: auto;

  .container {
    margin: 0 auto;
    max-width: 800px;
  }

  .title {
    margin-bottom: 0.8em;
  }

  a {
    color: #0d6efd;
  }

  .panel {
    background: #f6f6f6;
    border-radius: 10px;
    padding: 20px 20px 50px;
    margin-bottom: 10px;
  }

  .panel-show {
    margin-bottom: 50px;

    h2 {
      margin-bottom: 20px;
    }

    .row {
      padding: 2px 0;
    }

    .row:hover {
      background: #ededed;
      border-radius: 5px;
    }

    h6 {
      margin-left: 0;
      margin-bottom: 20px;
    }
  }

  .panel-room {
    .row {
      margin-bottom: 20px;
    }
  }

  .project-description {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .module {
    margin-bottom: 45px;
  }

  h6 {
    margin-bottom: 0.3em;
    margin-left: 15px;
  }

  .content-edit {
    margin-left: 15px;
    border: 1px solid #cdcdcd;
    background: #fdfdfd;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px 15px;
    font-size: 16px;
    line-height: 1.6;

    p {
      font-size: 16px;
      line-height: 1.6;
    }

    &:focus {
      background: #f6f6f6;
    }

    &.edited {
      background: $green;

      &:focus {
        background: $green;
      }
    }
  }

  .edited {
    .accordion-header button {
      background: $green;
    }
  }

  .col:first-child {
    flex: 1;
  }

  .col {
    flex: none;
    width: auto;
    text-align: left;
  }

  .col:last-child {
    text-align: right;
  }

  .edit-container {
    .title {
      display: block;
      margin-bottom: 1em;
      font-weight: 400;
    }

    .value {
      display: block;
      margin-bottom: 1em;
    }
  }
}
