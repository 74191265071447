.Gallery3d {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: none;
  background-color: $color-black;
  will-change: transform;

  .webgl {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;

    canvas {
      outline: none;
    }
  }

  &[data-cursor="default3d"] {
    cursor: url("../../assets/svg/embedded/cursor_default.svg") 10 10, auto;
  }

  &[data-cursor="plus3d"] {
    cursor: url("../../assets/svg/embedded/cursor_plus.svg") 16 16, auto;
  }

  &[data-cursor="minus3d"] {
    cursor: url("../../assets/svg/embedded/cursor_minus.svg") 16 16, auto;
  }
}

.title-cards {
  bottom: 12.5vh;
  left: 0;
  z-index: 2;
  width: 100%;
  display: none;
  opacity: 0;
}

.progress-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 0;
  transform: translateY(5px);
  background-color: $color-white;
}

.start {
  position: fixed;
  left: 50%;
  margin-left: -50px;
  z-index: 10;
  width: 100px;
  text-align: center;
  font-size: 11px;
  padding: 10px;
  font-family: Arial;
  color: #fff;
  text-transform: uppercase;

  @include for-size(tablet-portrait-up) {
    display: none;
  }
}

.controls {
  position: fixed;
  left: 0;
  bottom: 20px;
  width: 100%;
  z-index: 10;
  text-align: center;

  @include for-size(tablet-portrait-up) {
    display: none;
  }
}

.gallery-nav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  visibility: hidden;
  opacity: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  @extend .site-element-padding;

  a,
  div {
    padding: 0 15px;

    &:last-child {
      padding-right: 0;
    }
  }

  > a {
    display: flex;
    color: $color-white;
    text-transform: capitalize;

    &:hover {
      text-decoration: underline;
    }
  }

  .other-visitors {
    display: none;
    align-items: center;

    .visitors-icon {
      display: flex;
      margin-right: 10px;
    }

    .visitors-text {
      color: $color-white;
      opacity: 0.55;
      font-weight: 100;
    }

    .vr {
      background-color: $color-white;
      width: 1px;
      height: 17px;
      padding: 0;
      margin: 0 0 2px 27px;
    }
  }

  &.theme-dark {
    > a {
      color: $color-black;
    }

    .other-visitors {
      .visitors-text {
        color: $color-black;
      }

      .vr {
        background-color: $color-black;
      }
    }

    .visitors-icon {
      path {
        fill: $color-black;
      }
    }
  }
}

.scroll-dummy {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  overflow: auto;
  height: 100vh;
  display: none;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* firefox */
  &::-webkit-scrollbar {
    /* webkit */
    display: none;
  }

  // fixes snap scroll bug on safari that scrolls to top when rule applied
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      scroll-snap-type: y mandatory;
      -webkit-overflow-scrolling: touch;
    }
  }

  &:not(.disableSnap) {
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .content {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    pointer-events: none;

    .child {
      height: 100vh;
      scroll-snap-align: start;
      position: relative;

      //@media (hover: none) and (pointer: coarse) {
      //	height: 50vh;
      //}
    }
  }
}

.next,
.prev {
  display: inline-block;
  font-size: 11px;
  padding: 10px;
  font-family: Arial;
  color: #fff;
  text-transform: uppercase;
}

.next {
  left: 110px;
}

.title-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  text-align: center;
  background-color: rgba(25, 29, 26, 0.5);

  .flex-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .title {
    color: $color-white;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.1;
    margin-bottom: 20px;
    max-width: 75%;

    @media only screen and (min-width: $media-breakpoint-md) {
      font-size: 70px;
      margin-bottom: 30px;
    }

    @media only screen and (min-width: $media-breakpoint-xl) {
      font-size: 90px;
      margin-bottom: 50px;
    }

    @media only screen and (min-width: $media-breakpoint-xxl) {
      font-size: 120px;
      margin-bottom: 60px;
    }
  }

  .explore {
    color: $color-white;
    font-weight: 500;
    position: relative;
    @extend .type-unit-sub;

    &:after {
      content: "";
      position: absolute;
      bottom: -2px;
      width: 100%;
      height: 2px;
      background-color: $color-white;
      transition: all 0.2s ease-out;
      right: 0;
      pointer-events: none;

      @media only screen and (min-width: $media-breakpoint-md) {
        height: 3px;
      }
    }

    &:hover {
      &:after {
        width: 0;
      }
    }
  }

  .aboutButton {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    color: $color-white;
    font-weight: 500;
    @extend .site-element-padding;

    &:hover {
      text-decoration: underline;
    }
  }
}

.mute-button {
  span {
    pointer-events: none;

    &.unmute {
      display: none;
    }
  }

  &.muted {
    .mute {
      display: none;
    }

    .unmute {
      display: block;
    }
  }

  &.muted {
  }
}
